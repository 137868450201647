import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getGatsbyImageData } from "gatsby-source-sanity"
import { convertToBgImage } from "gbimage-bridge"
import SanityImage from "gatsby-plugin-sanity-image"
import { GatsbyImage, getImage, getImageData } from "gatsby-plugin-image"

import Hero, { HeroTitleDescription } from "../components/Hero"
import AboutCard from "../components/aboutCard"
import BannerTitleNew from "../components/BannerTitleNew"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import Divider from "../components/divider"
import BannerContainer from "../components/bannerContainer"
import WaveDivider from "../components/svgs/WaveDivider"
import PageContentContainer from "../components/PageContentContainer"
import { sanityConfig } from "../sanityConfig"
import BannerSection from "../components/BannerSection"
import { ExternalLink } from "../components/helpers"

const AboutCardContainer = ({ children }) => (
  <div className="mb-20 max-w-4xl ">{children}</div>
)

const AboutSectionTitle = ({ title = "TITLE" }) => (
  <BannerTitleNew
    className="mb-10 text-primary"
    props={{
      divider: (
        <WaveDivider className="hidden md:block fill-tertiary w-12 md:w-40" />
      ),

      doubleDivider: true,
      text: (
        <div className="grid justify-items-center">
          <h2 className="text-5xl font-semibold ">{title}</h2>
          <WaveDivider className="fill-tertiary px-5 w-1/2 mt-5 md:hidden" />
        </div>
      ),
    }}
  />
)

const About = ({ data }) => {
  const {
    banner,
    card1,
    card2,
    card3,
    windigoLogo,
    nnecLogo,
    shibogamaLogo,
    kobeLogo,
    ifnaLogo,
  } = data

  const councilsInfo = [
    {
      logo: ifnaLogo.childImageSharp,
      title: "Independent First Nations Alliance (IFNA)",
      url: "https://ifna.ca/",
    },
    {
      logo: kobeLogo.childImageSharp,
      title: "Keewaytinook Okimakanak (KOBE)",
      url: "https://www.kochiefs.ca",
    },
    {
      logo: shibogamaLogo.childImageSharp,
      title: "Shibogama First Nations Council (SFNC)",
      url: "https://www.shibogama.on.ca/",
    },

    {
      logo: windigoLogo.childImageSharp,
      title: "Windigo First Nations Council",
      url: "http://www.windigo.on.ca/",
    },
    {
      logo: nnecLogo.childImageSharp,
      title: "Northern Nishnawbe Education Council ",
      url: "https://www.nnec.on.ca/",
    },
  ]

  const aboutHeroFluid = banner.aboutBanner.asset.gatsbyImageData

  const bgImage = convertToBgImage(aboutHeroFluid)

  // const aboutCard1Fluid = getGatsbyImageData(
  //   card1?.aboutCard1?.asset.id,
  //   { maxWidth: 400 },
  //   sanityConfig
  // )

  const aboutCard1Fluid = getImage(card1?.aboutCard1?.asset)

  const aboutCard2Fluid = getImage(card2?.aboutCard2?.asset)

  const aboutCard3Fluid = getImage(card3?.aboutCard3?.asset)

  // const aboutHeroFluid = null

  const aboutCards = {
    whoWeServe: {
      title: "Who We Serve",
      text:
        "Our priority is our students – young people who have had to leave their families and communities, and are most likely dealing with a period of significant upheaval in their lives. Our focus is to encourage the development of physical wellness, positive mental health, and success both socially and academically.",
      image: aboutCard1Fluid,
    },
    howWeServe: {
      title: "How We Serve",
      text: (
        <>
          <p>
            Delivered from our centre at 57 King Street, our organization offers
            wide-ranging programming and events in areas including but not
            limited to: land-based activities, recreation, language, arts, and
            culture.
          </p>
          <br />
          <p>
            We work as a team with mental health counsellors, guest speakers,
            and knowledgeable First Nations service providers to create a
            calendar of events and programming that keeps our students active
            and engaged. Almost every day of the month has at least one
            organized activity for the students to look forward to, and we make
            sure to use all available resources both inside our facilities and
            outside on the land.
          </p>
          <br />
          <p>
            Input from the students is key to the development of our programs,
            and it’s often through simple interactions that we come up with some
            of our best ideas! Also important to us is staying focused on our
            overall mission and vision, ensuring that we keep to our long-term
            goals for our centre and community.
          </p>
        </>
      ),
      image: aboutCard2Fluid,
    },
    ourCommunity: {
      title: "Our Community",
      text:
        "W.I.N.K.S. brings students together with community organizations to provide a broader spectrum of support and a more inclusive circle of care. Working with community partners W.I.N.K.S. provides opportunities for students including: networking, resource sharing, planning, and strategizing. W.I.N.K.S. has partnered with the Sioux Lookout Public Library, Nishnawbe-Gamik Friendship Centre, Sioux North High School and Student Council, Northwestern Health Unit, Police Services, and other local groups.",
      image: aboutCard3Fluid,
    },
  }

  const aboutText =
    "W.I.N.K.S. Active Wellness Program is an interagency organization that works to support First Nations youth as they move to Sioux Lookout to attend secondary school."

  return (
    <Layout>
      <SEO title="About Us" />
      <Hero
        props={{
          heroFluid: bgImage.fluid,
          className: "bg-opacity-60",
        }}
      >
        <HeroTitleDescription title="About Us" text={aboutText} />
        {/* Children here */}
      </Hero>
      <PageContentContainer>
        <BannerContainer
          color="bg-secondary"
          className="min-h-screen py-20"
          children={
            <>
              {/* USE BANNER SECTION IN INDEX PAGE */}
              <BannerSection
                className="text-primary mb-20"
                mainHTML={
                  <>
                    <p>
                      W.I.N.K.S. is a collaboration between four local tribal
                      councils;{" "}
                      <ExternalLink url="https://www.kochiefs.ca">
                        Keewaytinook Okimakanak (KOBE)
                      </ExternalLink>
                      ,{" "}
                      <ExternalLink url="https://www.shibogama.on.ca/">
                        Shibogama First Nations Council (SFNC)
                      </ExternalLink>
                      ,{" "}
                      <ExternalLink url="http://www.windigo.on.ca/">
                        Windigo First Nations Council (WFNC)
                      </ExternalLink>
                      , and{" "}
                      <ExternalLink url="https://ifna.ca/">
                        Independent First Nations Alliance (IFNA)
                      </ExternalLink>
                      , as well as the{" "}
                      <ExternalLink url="https://www.nnec.on.ca/">
                        Northern Nishnawbe Education Council (NNEC)
                      </ExternalLink>
                      , and has a combined student membership of 280+ youth from
                      remote communities. For these students a move away from
                      home can represent a period of significant upheaval in
                      their lives, and many are in need of programs and
                      activities that support their physical, social, mental,
                      and cultural well-being. At W.I.N.K.S. we provide a safe
                      space where students feel supported - a home away from
                      home.
                    </p>
                    <p>
                      W.I.N.K.S. is an acronym that stands for our inter-agency
                      partners (Windigo, IFNA, NNEC, KOBE, and Shibogama).
                    </p>
                    <div className=" flex flex-wrap  items-center  w-full  justify-center ">
                      {councilsInfo.map((council, index) => (
                        <ExternalLink
                          url={council.url}
                          className="w-32 flex justify-center mr-5 mt-5"
                          key={`${index}-${council.title}-logo`}
                          title={council.title}
                        >
                          <GatsbyImage image={council.logo.gatsbyImageData} />
                        </ExternalLink>
                      ))}
                    </div>
                  </>
                }
              />
              <BannerSection
                title="Mission & Vision"
                className="text-primary mb-20"
                mainHTML={
                  <>
                    <p>
                      Our <span className="font-bold italic">mission</span> is
                      to provide active wellness programming to students
                      attending secondary school in Sioux Lookout, with a
                      primary focus on those students who have left their First
                      Nations communities.
                    </p>
                    <p>
                      Our <span className="font-bold italic">vision</span> is to
                      cultivate wellness and resilience in our students through
                      programming that heals, strengthens, and protects while
                      also embracing cultural connections to the land.
                    </p>
                  </>
                }
              />
              <div className="">
                <AboutCardContainer>
                  <AboutSectionTitle title={aboutCards.whoWeServe.title} />
                  <AboutCard
                    aboutCardFluid={aboutCards.whoWeServe.image}
                    className=""
                    classNameInner=" "
                    text={aboutCards.whoWeServe.text}
                    title={aboutCards.whoWeServe.title}
                  />
                </AboutCardContainer>

                <AboutCardContainer>
                  <AboutSectionTitle title={aboutCards.howWeServe.title} />
                  <AboutCard
                    aboutCardFluid={aboutCards.howWeServe.image}
                    className=""
                    classNameInner=" "
                    text={aboutCards.howWeServe.text}
                    title={aboutCards.howWeServe.title}
                  />
                </AboutCardContainer>

                <AboutCardContainer>
                  <AboutSectionTitle title="Our Community" />
                  <AboutCard
                    aboutCardFluid={aboutCards.ourCommunity.image}
                    className=""
                    classNameInner=""
                    text={aboutCards.ourCommunity.text}
                    title={aboutCards.ourCommunity.title}
                  />
                </AboutCardContainer>
              </div>
            </>
          }
        />
      </PageContentContainer>
    </Layout>
  )
}

export const query = graphql`
  query {
    banner: sanityAboutPage {
      aboutBanner {
        asset {
          gatsbyImageData(width: 1200, placeholder: BLURRED)
          id
          url
        }
      }
    }
    card1: sanityAboutPage {
      aboutCard1 {
        altText
        asset {
          gatsbyImageData(width: 1000, placeholder: BLURRED)
        }
      }
    }
    card2: sanityAboutPage {
      aboutCard2 {
        asset {
          gatsbyImageData(width: 1000, placeholder: BLURRED)
        }
      }
    }
    card3: sanityAboutPage {
      aboutCard3 {
        asset {
          gatsbyImageData(width: 1000, placeholder: BLURRED)
        }
      }
    }

    tcLogos: allFile(filter: { relativeDirectory: { eq: "tcLogos" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(width: 500, placeholder: BLURRED)
        }
      }
    }

    windigoLogo: file(relativePath: { eq: "tcLogos/Windigo logo.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    nnecLogo: file(relativePath: { eq: "tcLogos/nnec logo.png" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    shibogamaLogo: file(relativePath: { eq: "tcLogos/shibogama logo.png" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    kobeLogo: file(relativePath: { eq: "tcLogos/KOBE logo.png" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    ifnaLogo: file(relativePath: { eq: "tcLogos/IFNA Logo.png" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`

export default About
