import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

const AboutCard = ({
  text,
  title = "",
  className = "",
  classNameInner = "",
  aboutCardFluid,
  altText = "",
}) => {
  const data = useStaticQuery(graphql`
    query AboutCardQuery {
      defaultImage: file(
        relativePath: { eq: "denis-kirichenko-CAOMmLlQAOs-unsplash.jpg" }
      ) {
        id
        childImageSharp {
          gatsbyImageData(width: 300, placeholder: BLURRED)
        }
      }
    }
  `)

  const defaultImage = getImage(data.defaultImage)
  const alt = altText || "Placeholder Image"

  const cardImage = aboutCardFluid || defaultImage

  return (
    <div className={`relative ${className}`}>
      <div
        className={`relative flex flex-col items-center min-h-md  bg-primary shadow-card p-10 m-5 max-w-5xl ${classNameInner} `}
      >
        {/* {title && <div>{title}</div>} */}
        <GatsbyImage
          image={cardImage}
          className="z-10 shadow-cardWhite  "
          alt={alt}
        />

        <div className=" text-xl w-full mt-10">{text}</div>
      </div>
      {/* <div className="absolute -right-2 -bottom-2 w-11/12 h-full bg-white z-0 "></div> */}
    </div>
  )
}

export default AboutCard
