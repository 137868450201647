import React from "react"
import BannerTitleNew from "./BannerTitleNew"
import PageSectionContent from "./PageSection"
import WaveDivider from "./svgs/WaveDivider"

const BannerSection = ({
  title = "",
  mainHTML = <p>This is the main html</p>,
  className = "",
}) => {
  return (
    <section className={`${className}`}>
      {title && (
        <BannerTitleNew
          className="mb-10"
          props={{
            divider: (
              <WaveDivider className=" hidden md:block fill-tertiary w-12 md:w-40" />
            ),

            doubleDivider: true,
            text: (
              <div className="grid justify-items-center">
                <h2 className="text-5xl font-semibold ">{title}</h2>
                <WaveDivider className=" fill-tertiary px-5 w-1/2 mt-5 md:hidden" />
              </div>
            ),
          }}
        />
      )}
      <PageSectionContent className="text-xl">
        <div className="grid gap-y-6 leading-snug">{mainHTML}</div>
      </PageSectionContent>
    </section>
  )
}

export default BannerSection
