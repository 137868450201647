import React from "react"

export const ExternalLink = ({ url = "#", className, children }) => {
  return (
    <a
      href={url}
      className={`underline visited:text-purple-800 ${className}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  )
}
